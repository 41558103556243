<template>
  <div>
    <sub-head :subTitle="content['오시는 길']"></sub-head>
    <div class="page_padding">
      <div class="paddingTB">
        <div class="d-flex">
          <div class="mapImage width55">
            <image-component
              :is_jpg="false"
              name="map"
              path=""
            ></image-component>
          </div>
          <div class="mapInfo width45 text-left">
            <div class="company_name">
              <span class="fontBold font30 text333">(주)스프링소프트</span>
            </div>
            <div>
              <ul class="company_info">
                <li>
                  <ul>
                    <li><span class="font18">Adress</span></li>
                    <li>
                      <span class="font18"
                        >경기도 성남시 수정구 창업로 54 (시흥동 294-2)<br />LH
                        기업성장센터 807호</span
                      >
                    </li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <li><span>Tell</span></li>
                    <li><span>070-8864-0486</span></li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <li><span>E-mail</span></li>
                    <li><span>suzf@springsoft.io</span></li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <li><span>Fax</span></li>
                    <li><span>031-712-0486</span></li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsonContents from "../../assets/source/json/sub_top.json";
import ImageComponent from "../../components/imageComponent.vue";
import subHead from "../../components/sub_head.vue";
export default {
  components: {subHead, ImageComponent},
  data() {
    return {
      content: jsonContents,
      is_jpg: false,
    };
  },
};
</script>

<style scoped>
ul.company_info ul {
  margin: 1.5rem 0;
}
ul.company_info li {
  list-style: none;
}
ul.company_info ul li:last-child {
  margin-top: 1rem;
}
ul.company_info ul li:first-child {
  color: gray;
}
ul.company_info span {
  font-size: 16px;
}
.mapInfo {
  padding-left: 2rem;
  margin: auto 0;
}
@media (min-width: 1024px) and (max-width: 1280px) {
  ul.company_info span {
    font-size: 13px;
  }
  ul.company_info ul {
    margin: 0.5rem 0;
  }
  ul.company_info ul li:last-child {
    margin-top: 0.2rem;
  }
  .mapInfo {
    padding: 0 1rem;
  }
}
@media (max-width: 1023px) {
  .d-flex {
    flex-direction: column;
  }
  .mapImage.width55 {
    width: 100%;
  }
  .mapInfo {
    padding: 1rem 0;
    width: 100%;
  }
  ul.company_info > li:nth-child(2n-1) {
    display: inline-block !important;
    width: 75%;
  }
  ul.company_info > li:nth-child(2n) {
    display: inline-block !important;
    width: 25%;
  }
}
@media (max-width: 768px) {
  ul.company_info > li {
    display: inline-block !important;
    width: 100% !important;
  }
  ul.company_info ul {
    margin: 0.5rem 0;
  }
  ul.company_info ul li:last-child {
    margin-top: 0.8rem;
  }
  .company_name {
    padding: 0.5rem 0;
  }
}
</style>
